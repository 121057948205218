import { createStore } from 'vuex'
import axios from 'axios';
import router from './router';
import VuexPersistence from 'vuex-persist';
let url = window.location.origin;

if (process.env.NODE_ENV == 'development') {
    url = 'http://localhost:8000';
}

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default new createStore({
    state: {
        sidebar_drawer: true,
        right_sidebar_drawer: false,
        SidebarColor: 'white',
        SidebarBg: 'white',
        status: '',
        token: localStorage.getItem('token') || '',
        user: {},
        language: 'en',
        app_url: url + '/api/',
        translations: [],
        notifications: [],
        colors: {
            primary_color: "#58595B",
            secondary_color: "#58595B",
        },
        panels: {},
        profile_name: null,
        profile_image: null,
    },
    mutations: {
        setSidebarDrawer(state, payload) {
            state.sidebar_drawer = payload;
        },
        setRightSidebarDrawer(state, payload) {
            state.right_sidebar_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
        },
        auth_error(state) {
            state.status = 'error';
        },
        user(state, user) {
            state.user = user;
            state.profile_name = user.person ? user.person.casual_name : user.email;
            state.profile_image = user.person && user.person.file ? user.person.file.full_path : null;
        },
        logout(state) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
            axios.post(url + '/api/v2/logout').then(response => {
                console.log(response.data);
            });
            state.status = '';
            state.token = '';
            state.user = null;
            state.notifications = [];
            state.profile_image = null;
            state.profile_name = null;
        },
        setLanguage(state, payload) {
            state.language = payload;
        },
        setPanel(state, payload) {
            state.panels[payload.module_name] = payload.value;
        },
        refreshTranslations(state) {
            axios.get(url + '/api/v2/translations-without-login').then(response => {
                state.translations = response.data;
            });
        },
        setNotifications(state, payload) {
            state.notifications = payload;
        },
        setColors(state, payload) {
            state.colors = payload;
        },
        setProfileName(state, payload) {
            state.profile_name = payload;
        },
        setProfileImage(state, payload) {
            state.profile_image = payload;
        },
        setPowerBiToken(state, payload) {
            state.powerbi_token = payload;
        },
        setPowerBiExpDate(state, payload) {
            state.powerbi_exp_date = payload;
        },
        setPowerBiUrl(state, payload) {
            state.powerbi_url = payload;
        },
        setPowerBiSecondToken(state, payload) {
            state.powerbi_second_token = payload;
        },
        setPowerBiSecondExpDate(state, payload) {
            state.powerbi_second_exp_date = payload;
        },
        setReleaseVersion(state, payload) {
            state.release_version = payload;
        },
    },
    actions: {
        login({ commit }, auth) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({ url: url + '/api/v2/login', data: auth, method: 'POST' }).then(response => {
                    const token = response.data.token;
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    commit('auth_success', token);
                    resolve(response);
                }).catch(err => {
                    commit('auth_error');
                    localStorage.removeItem('token');
                    reject(err);
                });
            });
        },
        verified({ commit }) {
            return new Promise((resolve) => {
                axios.get(url + '/api/user').then(response => {
                    if(response.data.roles) {
                        response.data.roles.forEach((role) => {
                            if (role.id === 9) {
                                response.data.superuser = true;
                            }
                        });
                    }
                    commit('user', response.data);
                    if(localStorage.getItem('path')) {
                        router.push(localStorage.getItem('path'));
                    }
                    else if(response.data.assign_permission.includes("show_dashboard")) {
                        router.push('/dashboard');
                    }
                    else {
                        router.push('/');
                    }
                    resolve(response);
                });
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout');
                router.push('/login');
                resolve();
            })
        },
        setPowerBiToken({commit}, data) {
            commit('setPowerBiToken', data);
        },
        setPowerBiExpDate({commit}, data) {
            commit('setPowerBiExpDate', data);
        },
        setPowerBiUrl({commit}, data) {
            commit('setPowerBiUrl', data);
        },
        setPowerBiSecondToken({commit}, data) {
            commit('setPowerBiSecondToken', data);
        },
        setPowerBiSecondExpDate({commit}, data) {
            commit('setPowerBiSecondExpDate', data);
        },
        setLanguage({commit}, data) {
            commit('setLanguage', data);
        },
        refreshTranslations({commit}, data) {
            commit('refreshTranslations', data);
        },
        setPanel({commit}, data) {
            commit('setPanel', data);
        },
        setNotifications({commit}, data) {
            commit('setNotifications', data);
        },
        setColors({commit}, data) {
            commit('setColors', data);
        },
        setProfileName({commit}, data) {
            commit('setProfileName', data);
        },
        setProfileImage({commit}, data) {
            commit('setProfileImage', data);
        },
        setSidebarDrawer({commit}, data) {
            commit('setSidebarDrawer', data);
        },
        setRightSidebarDrawer({commit}, data) {
            commit('setRightSidebarDrawer', data);
        },
        setReleaseVersion({commit}, data) {
            commit('setReleaseVersion', data);
        },
    },
    getters: {
        isLoggedIn: state => !!state.user,
        token: state => state.token,
        authStatus: state => state.status,
        appUrl: state => state.app_url,
        getUserName: state => state.user.name,
        getUserEmail: state => state.user.email,
        getNotifications: state => state.notifications,
        getUserType: state => state.user.type,
        getUser: state => state.user,
        getLanguage: state => state.language,
        SidebarDrawer: state => state.sidebar_drawer,
        rightSidebarDrawer: state => state.right_sidebar_drawer,
        translate: (state) => (payload) => {
            if (state.translations.length > 0) {
                let result = state.translations.filter(translation => translation.key === payload && translation.language === state.language);
                if (result.length > 0) {
                    return result[0].value;
                }
                return payload;
            } else {
                return payload;
            }
        },
        getPanel: (state) => (payload) => {
            if(state.panels[payload]) {
                return Array.from(state.panels[payload]);
            }
            else {
                return [0];
            }
        },
        primaryColor: state => state.colors.primary_color,
        secondaryColor: state => state.colors.secondary_color,
        profileImage: state => state.profile_image,
        profileName: state => state.profile_name,
        getPowerBiToken: state => state.powerbi_token,
        getPowerBiExpDate: state => state.powerbi_exp_date,
        getPowerBiUrl: state => state.powerbi_url,
        getPowerBiSecondToken: state => state.powerbi_second_token,
        getPowerBiSecondExpDate: state => state.powerbi_second_exp_date,
        getReleaseVersion: state => state.release_version,
    },
    plugins: [vuexLocal.plugin]
})