<template>
  <v-list-item
      v-if="canShow(item)"
      :href="href"
      :to="item.to"
      base-color="black"
      class="pa-2"
      active-class="secondary white--text">
    <template v-if="item.icon" #prepend>
      <v-icon :icon="item.icon"/>
    </template>
    <template #title>
      {{ item.title }}
    </template>
  </v-list-item>
</template>

<script>
import {useAbility} from "@casl/vue";
import helpFunctions from "../../plugins/helpFunctions";
export default {
  name: "BaseItem",
  setup() {
    const { can } = useAbility();
    return {
      can
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    canShow(item) {
      if(item.gate.subject === 'home') {
        return true;
      }
      else if(item.gate.subject === 'search') {
        for (let i = 0; i < helpFunctions.can_search.length; i++) {
          if(this.canShowRelatedModel(helpFunctions.can_search[i])) {
            return true;
          }
        }
      }
      else if(item.gate.subject === 'inspectionsconfig') {
        for (let i = 0; i < helpFunctions.inspections_related_models.length; i++) {
          if(this.canShowRelatedModel(helpFunctions.inspections_related_models[i])) {
            return true;
          }
        }
      }
      else if(typeof(item.gate.subject) === 'string') {
        return this.can("show", this.$createEntity(item.gate.subject.toLowerCase(), { id: 0 }));
      }
      else {
        return false;
      }
    },
    canShowRelatedModel(model) {
      return this.can("show", this.$createEntity(helpFunctions.models[model].toLowerCase(), { id: 0 }));
    },
  },
  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
};
</script>