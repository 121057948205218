<template>
  <BaseDraggableModal
      name="custom_permissions"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="custom_permissions">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" :disabled="loading" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ml-5 mr-5">
            <v-autocomplete
                color="primary"
                bg-color="white"
                variant="underlined"
                :label="$store.getters.translate('user')"
                v-model="record.user_id"
                :return-object="false"
                item-value="id"
                item-title="name"
                :items="$lodash.sortBy(users, 'name')"
                @update:model-value="showPermissions"/>
            <v-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-checkbox :disabled="global_permissions.show" color="primary" v-model="record.show" :label="$store.getters.translate('show')"/>
                    </td>
                    <td>
                      <v-checkbox :disabled="global_permissions.edit" color="primary" v-model="record.edit" :label="$store.getters.translate('edit')"/>
                    </td>
                    <td>
                      <v-checkbox :disabled="global_permissions.delete" color="primary" v-model="record.delete" :label="$store.getters.translate('delete')"/>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["selected_model"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.custompermissions,
      loading: false,
      all_records: [],
      users: [],
      record: {},
      global_permissions: {}
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(model_type, model_id) {
      this.record = {
        user_id: null,
        model_id: model_id,
        model_type: model_type,
        show: false,
        edit: false,
        delete: false,
      };
      this.global_permissions = { show: false, edit: false, delete: false };
      this.openModal('custom_permissions');
      await this.getCustomPermissions();
      await this.getUsers();
    },
    async getCustomPermissions() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?model_type=" + this.record.model_type + "&model_id=" + this.record.model_id)
          .then((response) => {
            this.all_records = response.data;
            if (this.record.user_id) {
              this.showPermissions();
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    showPermissions() {
      this.record.show = false;
      this.record.edit = false;
      this.record.delete = false;
      let custom_permission = this.all_records.filter(permission => permission.user_id === this.record.user_id);
      if(custom_permission.length) {
        this.record.show = custom_permission[0].show;
        this.record.edit = custom_permission[0].edit;
        this.record.delete = custom_permission[0].delete;
      }
      //CHECK IF THE USER HAS PERMISSIONS BECAUSE OF HIS ROLE
      this.global_permissions = { show: false, edit: false, delete: false };
      let selected_user = this.users.filter(user => user.id === this.record.user_id);
      if(selected_user[0].assign_permission.includes("show_" + this.selected_model)) {
        this.record.show = true;
        this.global_permissions.show = true;
      }
      if(selected_user[0].assign_permission.includes("edit_" + this.selected_model)) {
        this.record.edit = true;
        this.global_permissions.edit = true;
      }
      if(selected_user[0].assign_permission.includes("delete_" + this.selected_model)) {
        this.record.delete = true;
        this.global_permissions.delete = true;
      }
    },
    async getUsers() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/users")
          .then((response) => {
            this.users = response.data;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async save() {
      if(!this.loading) {
        if (!this.record.user_id) {
          this.$toast.error(this.$store.getters.translate("please_select_user"));
        }
        else {
          this.loading = true;
          //NO NEED TO GIVE CUSTOM PERMISSIONS IF THE USER ALREADY HAS GLOBAL PERMISSIONS BECAUSE OF HIS ROLE
          if(this.global_permissions.show) {
            this.record.show = false;
          }
          if(this.global_permissions.edit) {
            this.record.edit = false;
          }
          if(this.global_permissions.delete) {
            this.record.delete = false;
          }
          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
              .then(async () => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_saved"));
                await this.getCustomPermissions();
              })
              .catch((error) => {
                this.$toast.error(error.message);
                this.loading = false;
              });
        }
      }
    },
    reset() {
      this.all_records = [];
      this.record = {};
      this.global_permissions = {};
      this.closeModal('custom_permissions');
    },
  },
};
</script>