<template>
  <v-row>
    <v-col>
      <v-form v-model="valid">
        <v-text-field
            v-for="(email, index) in updated_emailaddresses"
            :key="index"
            color="primary"
            variant="underlined"
            :disabled="isDisabled"
            :label="$store.getters.translate('emailaddress')"
            v-model="email.emailaddress"
            append-icon="mdi-trash-can"
            @click:append="deleteEmailaddress(email)"/>
        <v-text-field
            color="primary"
            variant="underlined"
            :disabled="isDisabled"
            :placeholder="$store.getters.translate('email')"
            v-model="new_emailaddress.emailaddress"
            validate-on="blur"
            append-icon="mdi-content-save"
            @click:append="add"
            :rules="emailRules"
            required
            v-on:keydown.enter.prevent="add"
            @blur="add"/>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["modelValue", "is_submit", "isDisabled"],
  data() {
    return {
      updated_emailaddresses: [],
      new_emailaddress: {},
      valid: true,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
      ],
    };
  },
  mounted() {
    this.new_emailaddress = {
      emailaddress: "",
      active: 0
    };
    this.updated_emailaddresses = this.modelValue ? this.modelValue : [];
  },
  methods: {
    add() {
      if (this.valid && this.new_emailaddress.emailaddress !== "") {
          this.updated_emailaddresses.push(this.new_emailaddress);
          this.new_emailaddress = {
            emailaddress: "",
            active: 0,
          };
        this.$emit("update", this.updated_emailaddresses);
      }
    },
    check(value) {
      let validator = require("email-validator");
      return validator.validate(value);
    },
    deleteEmailaddress(row) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.updated_emailaddresses = this.updated_emailaddresses.filter((record) => record.id !== row.id || record.emailaddress !== row.emailaddress);
          if (this.updated_emailaddresses.length == 0) {
            this.$emit('email_deleted');
          }
          if (row.id) {
            this.$http
                .delete(this.$store.getters.appUrl + "v2/emailaddresses/" + row.id)
                .then(() => {
                  this.$toast.success(this.$store.getters.translate("success"));
                  this.$emit("update", this.updated_emailaddresses);
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(error.message);
                  }
                });
          }
        }
      });
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.updated_emailaddresses = this.modelValue ? this.modelValue : [];
      },
    },
    is_submit:function() {
        this.new_emailaddress.emailaddress = '';
    }
  },
};
</script>